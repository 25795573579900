import type { Benefit } from "../../../shared/elements/BenefitList";

export const ABBREVIATED_STANDARD_BENEFITS = [
  { positive: true, label: "Signering med BankID" },
];
export const ABBREVIATED_SIMPLE_BENEFITS = [
  { positive: true, label: "Signering med InkSign" },
];
export const ABBREVIATED_PREMIUM_BENEFITS: Benefit[] = [
  { positive: true, label: "Trygg signering med BankID" },
  { positive: true, label: "Gebyrfri betaling med Vipps" },
  { positive: true, label: "Eierskifte hos Statens vegvesen" },
];
